<!-- //
登录页面
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="container">
    <div class="mask"/>

    <div class="login-container">
      <div class="login-logo">
        <img :src="logoImg"/>
      </div>
      <!-- left -->
      <div class="slogan-container">
        <div class="title">
          <div>客户懂你，</div>
          <div>主动找你</div>
        </div>
        <div class="summary">
          <div>认证创意人，上传真实作品</div>
          <div>让喜欢你的用户主动找你</div>
        </div>
      </div>

      <!-- login -->
      <div class="login-body">
        <div class="login-context">
          <a-tabs :activeKey="loginForm.loginType" @change="handleLoginChange" :animated="false" class="login-tab">
            <a-tab-pane key="pwdLogin" tab="密码登陆">
              <div class="form-box">
                <a-form-model ref="loginForm_pwdLogin" :model="loginForm" :rules="rules">
                  <div class="tab-row row-phone">
                    <a-form-model-item prop="area">
                      <a-select v-model="loginForm.area"
                                class="area"
                                option-label-prop="value"
                                @change="handleAreaChange">
                        <a-select-option value="+86">中国+86</a-select-option>
                        <a-select-option value="+852">中国香港 (+852)</a-select-option>
                        <a-select-option value="+886">中国台灣 (+886)</a-select-option>
                        <a-select-option value="+81">日本 (+81)</a-select-option>
                        <a-select-option value="+1">美国 (+1)</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item prop="mobilePhone">
                      <a-input v-model="loginForm.mobilePhone"
                               :maxLength="11"
                               class="phone"
                               placeholder="手机号">
                      </a-input>
                    </a-form-model-item>
                  </div>
                  <div class="tab-row row-password">
                    <a-form-model-item prop="password">
                      <a-input v-model="loginForm.password" type="password"
                               :maxLength="20"
                               class="password"
                               placeholder="登录密码">
                      </a-input>
                    </a-form-model-item>
                  </div>
                  <div class="tab-row row-forget">
                    <a class="forget" @click="handleForgetPwd">忘记密码？</a>
                  </div>
                </a-form-model>
              </div>
            </a-tab-pane>

            <a-tab-pane key="smsCodeLogin" tab="验证码登录">
              <div class="form-box">
                <a-form-model ref="loginForm_smsCodeLogin" :model="loginForm" :rules="rules">
                  <div class="tab-row row-phone">
                    <a-form-model-item prop="area">
                      <a-select v-model="loginForm.area"
                                class="area"
                                @change="handleAreaChange">
                        <a-select-option value="+86">+86</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item prop="mobilePhone">
                      <a-input v-model="loginForm.mobilePhone"
                               :maxLength="11"
                               class="phone"
                               placeholder="手机号">
                      </a-input>
                    </a-form-model-item>
                  </div>
                  <div class="tab-row row-smsCode">
                    <a-form-model-item prop="smsCode">
                      <a-input v-model="loginForm.smsCode"
                               :maxLength="6"
                               class="smsCode"
                               placeholder="手机验证码">
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                      <CountDown class="send" @sendClick="handleSendMsg"/>
                    </a-form-model-item>
                  </div>
                </a-form-model>
              </div>
            </a-tab-pane>
          </a-tabs>
          <a-row class="form-box row-protocol">
            <a-col class="protocol">登录即代表您同意《用户协议》和《隐私协议》</a-col>
          </a-row>
          <div class="form-box row-button">
            <a-button type="primary" class="btn-login" :loading="loading" @click="handleSubmitForm()">登录</a-button>
            <a-button type="primary" class="btn-wechat" @click="handleWechatLogin">
              微信登录
              <img :src="logoWechat" style="width: 20px;height: 16px; margin-left: 8px">
            </a-button>
          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="footer">
        <span class="footer-text">Copyright@艺住有限责任公司 其他文字详情描述 证照齐全</span>
      </div>

      <!-- 修改密码dialog -->
      <a-modal v-model="showModifyPwd"
               width="773px"
               :bodyStyle="{'height': '542px'}"
               :footer="null"
               :keyboard="false"
               :maskClosable="false"
               :destroyOnClose="true">
        <PwdForget @handleOk="handleModifyPwdCallback"/>
      </a-modal>

      <!-- 选择登录平台dialog -->
      <a-modal v-model="showPlatformChoose"
               width="904px"
               :bodyStyle="{'height': '565px'}"
               :closable="false"
               :footer="null"
               :keyboard="false"
               :maskClosable="false"
               :destroyOnClose="true">
        <PlatformChoose @handleOk="handlePlatformChooseCallback"/>
      </a-modal>
      <!-- 重置密码dialog -->
      <a-modal v-model="showResetPwd"
               width="773px"
               :bodyStyle="{'height': '542px'}"
               :footer="null"
               :keyboard="false"
               :maskClosable="false"
               :destroyOnClose="true">
        <PwdReset @handleOk="handleResetPwdCallback"/>
      </a-modal>

      <!-- 微信扫码登录dialog -->
      <WechatLogin ref="refWechatLogin" @handleOk="handleWxLoginCallback"/>
    </div>
  </div>


</template>

<script>
  import {validatePass, validateUser} from './loginUtil.js'
  import * as LoginApi from '@/api/auth.api.js'
  import * as Encryptor from '@/utils/encryptor'
  import CountDown from "@/components/CountDown";
  import PwdForget from "./pwd-forget";
  import PwdReset from "./pwd-reset";
  import PlatformChoose from "./platform-choose";
  import WechatLogin from "./wechat-login-jquery";
  import logoImg from '@/assets/img/login-logo.png'
  import logoWechat from '@/assets/img/login-wechat.png'

  const md5 = require('md5-js');

  export default {
    name: 'Login',
    components: {CountDown, PwdForget, PwdReset, PlatformChoose, WechatLogin},
    data() {
      return {
        loginForm: {
          loginType: "pwdLogin", // pwdLogin、smsCodeLogin
          area: '+86',
          mobilePhone: null,
          password: null,
          smsCodeKey: null,
          smsCode: null,
        },
        logoImg: logoImg,
        logoWechat: logoWechat,
        redirect: null,
        loading: false,
        showPlatformChoose: false, // 登录后选择平台
        showModifyPwd: false, // 修改密码
        showResetPwd: false, // 重置密码
        rules: {
          mobilePhone: [
            {validator: validateUser, trigger: 'blur'}
          ],
          password: [
            {validator: validatePass, trigger: 'blur'}
          ],
          smsCode: [
            {required: true, message: '手机验证码必须输入'}
          ],
        }
      };
    },
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect;
        },
        immediate: true
      },
    },
    computed: {},
    mounted() {
      let wxCode = this.$route.query.code;
      if (wxCode) {
        this.$store.dispatch('doLogin', {loginType: 'wechatQrLogin', code: wxCode}).then((data) => {
          // 登录成功后，选择需要进入的系统
          // 1-已经重置过密码，2-没重置过密码
          if (data.isAlreadyResetPwd == 2) {
            this.showResetPwd = true
          } else {
            this.showPlatformChoose = true
          }
        }).catch(err => {
          console.error(err)
        });
      }
    },

    methods: {
      /**
       * 切换登录方式
       */
      handleLoginChange(val) {
        this.loginForm.loginType = val
      },

      /**
       * 区号变更
       */
      handleAreaChange(val) {
      },
      /**
       * 登录
       */
      handleSubmitForm() {
        this.$refs["loginForm_" + this.loginForm.loginType].validate(valid => {
          if (valid) {
            this.loading = true;
            if (this.loginForm.loginType === 'pwdLogin') {
              // this.loginForm.password = md5(this.loginForm.password);
              this.loginForm.password = Encryptor.encrypt(this.loginForm.password);
            }
            // let ss = Encryptor.decrypt(this.loginForm.password)
            // console.log("========>",ss)
            // console.log(1)
            this.$store.dispatch('doLogin', this.loginForm).then((data) => {
              this.loading = false;
              // console.log(4)
              // 登录成功后，选择需要进入的系统
              if (this.loginForm.loginType === 'pwdLogin') {
                this.showPlatformChoose = true
              } else {
                // 1-已经重置过密码，2-没重置过密码
                if (data.isAlreadyResetPwd == 2) {
                  this.showResetPwd = true
                } else {
                  this.showPlatformChoose = true
                }
              }
              // window.location.href = "/index"
              // this.$router.push({path: this.redirect || "/index"});
            }).catch(err => {
              this.loading = false;
              console.error(err)
            });
          }
        });
      },

      // 发送验证码
      handleSendMsg(params, callback) {
        this.$refs["loginForm_" + this.loginForm.loginType].validateField(['mobilePhone'], (errorMessage) => {
          if (errorMessage) {
            console.log(errorMessage)
            return;
          } else {
            LoginApi.sendSmsCode({
              area: this.loginForm.area,
              phone: this.loginForm.mobilePhone,
            }).then(data => {
              this.loginForm.smsCodeKey = data;
              callback()
            })
          }
        })
      },

      /**
       * 忘记密码
       */
      handleForgetPwd() {
        this.showModifyPwd = true;
      },

      /**
       * 修改密码成功后
       */
      handleModifyPwdCallback() {
        this.showModifyPwd = false;
      },

      /**
       * 选择的跳转的平台
       *
       * @param platform {name: 'mch'}
       */
      handlePlatformChooseCallback(platform) {
        window.location.href = "/index"
      },

      /**
       * 重置密码
       */
      handleResetPwdCallback() {
        this.showResetPwd = false;
        this.showPlatformChoose = true
      },

      /**
       * 微信登录成功回调
       * （前提示微信扫码收，微信回调服务端接口）
       * @param session
       */
      handleWxLoginCallback(session) {
        this.$refs.refWechatLogin.hideModal();
        // 登录数据保存到store中
        this.$store.dispatch('doSetSession', session).then((data) => {
          this.showPlatformChoose = true
        });
      },

      /**
       * 微信登录
       */
      handleWechatLogin() {
        this.$refs.refWechatLogin.showModal()

        // let url = '';
        // // url = 'https://open.weixin.qq.com/connect/qrconnect?appid=wx7fbbdb5f6629b42b&redirect_uri=https://haohaolook.com%2Fapi%2Fuser%2Fbusiness%2Fwx-login%2Fcallback&response_type=code&scope=snsapi_login&state=coderkun#wechat_redirect'
        // url = 'https://open.weixin.qq.com/connect/qrconnect?appid=wx7fbbdb5f6629b42b&redirect_uri=https://yikaishop.com%2Flogin&response_type=code&scope=snsapi_login&state=coderkun#wechat_redirect'
        // window.location.href = url;
      },
    },
  };
</script>

<style lang="scss">
  .container {
    /** 验证失败字体大小 */
    .has-error .ant-form-explain,
    .has-error .ant-form-split {
      font-size: 12px;
    }

    /** tab栏文字*/
    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #1E1E1E;
    }

    /** tab栏文字*/
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #1E1E1E;
      font-weight: 700;
    }

    /** tab栏的线*/
    .ant-tabs-ink-bar {
      background-color: #1E1E1E;
      /*width: 70px !important;*/
    }

    /** 下拉框边框颜色*/
    .ant-select-selection {
      border: 1px solid #BFBFBF;
    }

    /** 下拉框箭头颜色*/
    .ant-select-arrow .ant-select-arrow-icon {
      color: #2B2B2B;
    }

    /** 下拉框高度 */
    .ant-select-selection--single {
      height: 38px;
    }

    /** 下拉框值 */
    .ant-select-selection-selected-value {
      color: #1E1E1E;
      font-weight: 500;
      /*font-family: 'Source Han Sans CN';*/
      font-size: 13px;
    }

    /** 下拉框值 */
    .ant-select-selection__rendered {
      line-height: 36px;
    }

    /** 输入框 */
    .ant-input {
      color: #2B2B2B;
      font-weight: 500;
      /*font-family: 'Source Han Sans CN';*/
      border: 1px solid #BFBFBF;
      font-size: 13px;
      height: 38px !important;
    }

    /** form item 边距重新调整 */
    .ant-form-item {
      /*margin-bottom: 0px;*/
      margin-right: 0;
    }

    /** form item 边距重新调整 */
    .row-phone .ant-form-item {
      /*margin-bottom: 20px;*/
    }

    /** form item 边距重新调整 */
    .row-password .ant-form-item {
      /*margin-bottom: 8px;*/
    }

    /** form item 边距重新调整 */
    .ant-form-inline .ant-form-item-with-help {
      /*margin-bottom: 0;*/
    }
  }
</style>

<style scoped lang="scss">
  .container {
    height: 100vh;
    width: 100%;
    background: url("../../assets/img/login-bg.png") no-repeat center fixed;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;

    .mask {
      height: 100%;
      width: 100%;
      background: rgba(43, 43, 43, 0.5);
      position: absolute;
      z-index: 0;
    }
  }

  .login-container {
    height: 100%;
    width: 100%;
    z-index: 2;
    align-items: center;

    display: flex;
    justify-content: center;

    .login-logo {
      position: fixed;
      top: 30px;
      left: 254px;
    }

    .slogan-container {
      top: 354px;
      left: 254px;
      position: absolute;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;
        color: #FFFFFF;
      }

      .summary {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: #D9D9D9;
      }
    }

    .footer {
      width: 100%;
      height: 60px;
      position: absolute;
      bottom: 0;
      background: rgba(30, 30, 30, 0.5);
      box-shadow: 0px 4px 16px rgba(211, 211, 211, 0.22);
      display: flex;
      justify-content: center;
      align-items: center;

      .footer-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #BFBFBF;
      }
    }

    .login-body {
      z-index: 2;
      width: 380px;
      height: 542px;
      position: absolute;
      right: 232px;
      overflow: hidden;
      background-color: #FFFFFF;
      box-shadow: 0 4px 25px 4px rgba(0, 0, 0, 0.21);
      border-radius: 10px;

      .login-context {
        padding: 100px 57px 0 60px;
      }
    }

    .form-box {
      width: 263px;
      margin-top: 35px;

      .tab-row {
        margin-top: -5px;
        display: flex;
      }
    }

    .row-phone {
      .area {
        width: 81px;
        height: 38px !important;
        margin-right: 12px;
      }

      .phone {
        width: 170px;
      }
    }

    .row-password {
      margin-bottom: -20px;

      .password {
        width: 263px;
      }
    }

    .row-smsCode {
      .smsCode {
        width: 140px;
        height: 38px !important;
        margin-right: 8px;
      }

      .send {
        width: 115px;
        height: 39px !important;
        border-radius: 2px;
        border-color: #1E1E1E;
        background-color: #1E1E1E;
      }
    }

    .row-forget {
      height: 32px;
      justify-content: right;
      font-weight: 400;
      font-size: 11px;
      margin-top: -55px;

      .forget {
        text-decoration: underline;
        color: #1E1E1E;
      }
    }

    .row-protocol {
      position: absolute;
      top: 315px;

      .protocol {
        font-weight: 700;
        font-size: 11px;
        color: #595959;
      }
    }

    .row-button {
      position: absolute;
      top: 350px;
      height: 39px;
      color: #1E1E1E;

      .btn-login {
        font-weight: 500;
        font-size: 12px;
        width: 157px;
        height: 39px;
        border-radius: 2px;
        border-color: #1E1E1E;
        background-color: #1E1E1E;
      }

      .btn-wechat {
        font-weight: 500;
        font-size: 12px;
        width: 100px;
        height: 39px;
        color: #1E1E1E;
        margin-left: 6px;
        background-color: #FFFFFF;
        border-color: #1E1E1E;
        border-radius: 2px;
      }
    }
  }
</style>
