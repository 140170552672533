<template>
  <div class="dlg-reset-container">
    <div class="header">
      <div class="title">设置登录密码</div>
    </div>
    <div class="body">
      <a-form-model ref="refForm" :model="form" :rules="rules">
        <a-form-model-item prop="password">
          <a-input v-model="form.password"
                   type="password"
                   class="password"
                   style="width: 262px"

                   :readOnly="readonly"
                   @blur="readonly = true"
                   @focus.native="readonly = false"
                   @click.native="readonly = false"

                   placeholder="重置密码8到16位必须包含数字和字母">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="confirmPassword">
          <a-input v-model="form.confirmPassword"
                   type="password"
                   class="password"
                   style="width: 262px"
                   placeholder="确认密码跟设置的密码一致">
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="footer">
      <a-button type="primary" class="btn-ok" @click="handleSubmitForm()">确认</a-button>
    </div>
  </div>
</template>

<script>
  import * as LoginApi from '@/api/auth.api.js'
  import {validatePass} from "@/views/login/loginUtil";
  import * as Encryptor from "@/utils/encryptor";
  import * as util from "@/utils/util";

  export default {
    name: 'PasswordReset',
    data() {
      return {
        readonly: true,
        form: {
          password: null,
          confirmPassword: null,
        },
        rules: {
          password: [
            {validator: validatePass, trigger: 'blur'}
          ],
          confirmPassword: [
            {validator: this.validateCheckPass, trigger: 'blur'}
          ],
        }
      };
    },
    methods: {
      /**
       * 用户确认密码校验
       */
      validateCheckPass(rule, value, callback) {
        if (util.isEmpty(value)) {
          callback(new Error('请输入确认密码'));
        }
        if (value !== this.form.password) {
          callback(new Error('确认密码必须和新密码一致'))
        } else {
          callback();
        }
      },

      /**
       * 确定
       */
      handleSubmitForm() {
        let password = Encryptor.encrypt(this.form.password);
        let confirmPassword = Encryptor.encrypt(this.form.confirmPassword);
        LoginApi.resetPwd({password, confirmPassword}).then(data => {
          this.$modal.msg("重置密码成功")
          this.$emit('handleOk')
        })
      },
    },
  };
</script>

<style scoped lang="scss">
  .dlg-reset-container {
    height: 100%;

    .header {
      text-align: center;
      padding: 16px 0;

      .title {
        font-size: 18px;
        font-weight: 700;
        color: #1E1E1E;
      }

      .desc {
        font-size: 16px;
        color: #8C8C8C;
        margin-top: 6px;
      }
    }

    .body {
      padding: 30px 20px;
      text-align: center;

      .row {
        padding: 10px 0;
      }

      .card {
        height: 126px;
        border-radius: 12px;
      }

      .active {
        border: 1px solid #1E1E1E;
        box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2);
      }
    }

    .footer {
      text-align: center;
      margin-top: 10px;

      .btn-ok {
        font-weight: 500;
        font-size: 12px;
        width: 263px;
        height: 39px;
        border-color: #1E1E1E;
        background-color: #1E1E1E;
      }
    }
  }
</style>
